<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-title="$t(`cip.plat.sysOps.stat.title.functionRecordTitle`)"
        ></head-layout>
      </el-col>
    </el-row>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
    </grid-layout>
  </div>
</template>

<script>

import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {getFunctionList} from "@/api/system/statistic";


export default {
  name: "functionRecord",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "functionName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.sysOps.stat.field.functionName"),
        },
        {
          prop: "functionCode",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.sysOps.stat.field.functionCode"),
        },
        {
          prop: "operationUser",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.sysOps.stat.field.operationUser"),
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        menu: false,
        column: [
          {
            label: this.$t('cip.plat.sysOps.stat.field.functionName'),
            prop: 'functionName',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sysOps.stat.field.functionCode'),
            prop: 'functionCode',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sysOps.stat.field.operationUser'),
            prop: 'operationUser',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sysOps.stat.field.createTime'),
            prop: 'createTime',
            overHidden: true,
          },
        ],
      }
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getFunctionList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
